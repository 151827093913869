import { useRef } from "react";
import { useAbortableEffect } from "./abort";
export function useKeepScroll(ref) {
    return void 0 === ref && (ref = useRef(null)), useAbortableEffect((signal)=>{
        if (!ref.current) return;
        let element = ref.current;
        element.dataset.keepscroll = "true", element.addEventListener("scroll", ()=>{
            element.dataset.scrollTop = element.scrollTop + "", element.dataset.scrollLeft = element.scrollLeft + "";
        }, {
            signal,
            passive: !0
        });
    }, [
        ref
    ]), ref;
}
